.loader-unique {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 50px; /* Increased the gap for better separation */
}

.icon-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.save-button-unique {
  margin-top: 20px;
  padding: 15px 40px;
  border-radius: 50px;
  background-color: #17A34A;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  outline: none;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.save-button-unique:hover {
  background-color: #139239;
  transform: scale(1.05);
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px; /* Adjusted gap for better visual separation */
}

.progress-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar {
  width: 100%;
  width: 300px;
  height: 20px;
  background-color: #f3f4f6;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}

.progress-fill {
  height: 100%;
  background-color: #17A34A;
  transition: width 1s ease-in-out;
}

.timer-seconds {
  font-size: 18px; /* Increased font size for better visibility */
  color: #555;
  text-align: center;
  margin-top: 5px; /* Added margin for clearer separation */
}

.panWrapper-unique {
  width: 180px; /* Adjusted width for better proportion */
  margin-top: 60px; /* Increased separation from the loading elements */
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.pan-unique {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: fit-content;
  animation: cooking-unique 1.7s infinite;
}

@keyframes cooking-unique {
  0% {
    transform: rotate(0deg);
    transform-origin: top right;
  }
  10% {
    transform: rotate(-4deg);
    transform-origin: top right;
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.fuud-unique {
  position: absolute;
  width: 20%;
  height: 4px;
  background: linear-gradient(to bottom, rgb(82, 33, 33), rgb(200, 106, 106));
  left: 14px;
  border-radius: 50%;
  animation: flip-unique 1.7s infinite;
  z-index: 2;
  top: 10px; /* Adjusted to prevent overlapping with the progress bar */
}

@keyframes flip-unique {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-50px) rotate(180deg); /* Diminuído de -100px para -50px */
  }
  100% {
    transform: translateY(0px) rotate(360deg);
  }
}


.panBase-unique {
  z-index: 3;
  width: 50%;
  height: 22px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: linear-gradient(to top, rgb(3, 156, 57), rgb(10, 191, 64));
}

.panHandle-unique {
  width: 40%;
  background: linear-gradient(to bottom, rgb(18, 18, 18), rgb(74, 74, 74));
  height: 10px;
  border-radius: 10px;
}

.panShadow-unique {
  width: 70px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.21);
  margin-left: 15px;
  border-radius: 10px;
  animation: shadow-unique 1.7s infinite;
  filter: blur(5px);
}

@keyframes shadow-unique {
  0% {
    transform: scaleX(0.7);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0.7);
  }
}
