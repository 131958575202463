/* Estilo para o container de chat */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kreon:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.chat-conteiner div {
  font-family: 'Inter', sans-serif;
}

.chat-inputs {
  width: 60%;
  padding: 10px;

}

.chat-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 17px;
}

.chat-input-peso {
  margin-top: 5px;
  position: relative;
}

.weight-label {
  position: absolute;
  top: -25px; /* Ajuste conforme necessário */
  left: 0;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 5px;
  visibility: hidden;
}

.weight-label.visible {
  visibility: visible;
}

.input-style {
  border: 2px solid #E2E8F0; /* Cor cinza claro para a borda normal */
  border-radius: 5px;
  padding: 8px 10px;
}

.input-style:focus {
  border-color: #17A2B8; /* Verde-teal especificado */
  outline: none;
}


.imagem-logo {
  margin-top: -50px;
  right: 0px;
}

.chat-title {
  background-color: #17A34A;
  color: white;
  width: 55%;
  border-radius: 17px;
  font-size: 20px;
  padding: 10px;
}

.chat-input label {
  margin-bottom: 10px;
}

.chat-input ::placeholder {
  color: #696767;
}

.chat-input input {
  appearance: none;
  border: 1px solid #dee2e7;
  /* Substitua pelo valor da borda desejada */
  border-radius: 0.375rem;
  width: 100%;
  padding: 16px;
  color: #374151;
  line-height: 1.25;
  outline: none;

}

.chat-input input:focus {
  border-color: #17A34A;
  border-width: 1px;

}

.chat-input select {
  appearance: none;
  border: 1px solid #dee2e7;
  /* Substitua pelo valor da borda desejada */
  border-radius: 0.375rem;
  width: 100%;
  padding: 16px;
  color: #374151;
  line-height: 1.25;
  outline: none;
  background-color: white;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  /* Ajuste este valor conforme necessário para o espaçamento desejado */
}

.select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 12px 16px;
  padding-right: 40px; /* Espaço para o ícone da seta */
  border: 1px solid #dee2e7;
  border-radius: 0.5rem;
  width: 100%;
  color: #2b2b2b;
  line-height: 1.5;
  outline: none;
  background-color: white;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.1);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" fill="none" stroke="%23374151" stroke-width="2"/><polyline points="8 10 12 14 16 10" fill="none" stroke="%23374151" stroke-width="2"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  cursor: pointer;
}







.button-inputF {
  border: 1px solid #756c6cad;
  background-color: #FFFFFF1C;
  color: gray;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.button-inputM {
  border: 1px solid #756c6cad;
  background-color: #FFFFFF1C;
  color: gray;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.button-inputF:focus {
  border: 1px solid #00A81B;
  background-color: #84e75a2d;
  color: #00A81B;
  border-radius: 10px;
}

.button-inputM:focus {
  border: 1px solid #00A81B;
  background-color: #84e75a2d;
  color: #00A81B;
  border-radius: 12px;
}

.food-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 13px;
  margin: 10px auto;
  /* Espaçamento entre os alimentos */
}

.food-item {
  min-width: calc(29%);
  width: 53px;
  max-width: calc(40%);
  /* Define a largura dos itens com base em 30% do container e subtrai o espaçamento */
  margin-bottom: 10px;
  /* Adiciona espaço abaixo de cada item */
  display: flex;
  white-space: normal;
  align-items: center;
  justify-content: center;
  height: 43px;
  background-color: #ffffff;
  /* Fundo branco para um visual clean */
  border: 1px solid #bababa;
  /* Borda suave */
  border-radius: 20px;
  /* Bordas arredondadas */
  font-style: normal;
  color: #333;
  /* Cor do texto */
  font-weight: 500;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  /* Cursor pointer para indicar interatividade */
}


.selectedfood {
  background-color: #17A34A;
  /* Verde quando selecionado */
  color: #fff;
  /* Cor do texto branco quando selecionado */
  border: 1px solid #ffffff;
  /* Borda suave */

}

.checkmark {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  /* Alinha o texto ao centro */
  max-width: 100%;
  font-style: normal;
  font-weight: 500;
  /* Define a largura máxima para que o conteúdo não quebre a linha */
}

@media screen and (max-width: 600px) {

  .button-inputF,
  .button-inputM {

    border: 1px solid #756c6cad;
    background-color: #FFFFFF1C;
    color: gray;
    border-radius: 12px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);


  }

  .selected {
    border: 1px solid #00A81B;
    background-color: #84e75a2d;
    color: #00A81B;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    /* Remove o contorno de foco padrão */
  }

  .selectedF {
    border: 1px solid #C100A2BD;
    background-color: #CC5FA11C;
    color: #C100A2BD;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    /* Remove o contorno de foco padrão */
  }
}

.gradient-header {
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 22px;
  color: #187E3D;
  border-radius: 10px;
}

.selection-food {
  width: 60%;
  margin: 20px auto;
  background-color: #ffffffc8;


}

.send-button {
  background-color: #3DAD0ABA;
  color: white;
  border-radius: 10px;
}

.label-genero {

  color: gray;
  padding: 10px;
  border-radius: 10px;
}

.chat-response {
  margin: auto;
  width: 60%;
}

textarea {
  border: #928f8f75 1px solid;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}



/* Estilo para a área de cabeçalho do chat */

.loading-icon {
  animation: spin 5s ease-in-out infinite;
  /* Aumente a duração e use ease-in-out para uma animação suave */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Estilo para os campos de entrada de texto e botões */





/* Estilo para o botão de enviar */
.send-button {
  background-color: #72A71F;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  transition: background-color 0.3s ease;
  animation: pulse 7s infinite;
  /* Adiciona a animação 'pulse' */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
    /* Aumenta ligeiramente o tamanho */
  }

  100% {
    transform: scale(1);
  }
}

.send-button:hover {
  background-color: #7797cc;
}

.download-button1 {
  background-color: #19e638;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}

/* Estilo para o botão de baixar PDF */
.download-button {
  background-color: #e64a19;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}

.download-button:hover {
  background-color: #ff5733;
  border-radius: 20px;
}

/* Estilo para o rótulo do campo de seleção de objetivo */


/* Estilos para o overlay e mensagem de carregamento */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-message {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 20px;
  color: #09cc0f;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* CSS para bloquear a rolagem da página */
.scroll-lock {
  overflow: hidden;
}

/* Estilo para os campos de seleção de alimentos */
.food-selection {
  display: flex;

  flex-wrap: wrap;
  /* Permite que as opções de alimentos quebrem para a próxima linha */
  gap: 10px;
  /* Espaço entre as opções de alimentos */

  /* Largura máxima para limitar o número de colunas */
}

.food-selection label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #555;
}

.food-selection label input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Estilo personalizado para checkboxes */
.custom-checkbox input[type="checkbox"] {
  display: none;


}

.shadowCard {
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2), 0 8px 10px rgba(0, 0, 0, 0.1);
}

.custom-checkbox label {
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]+label:before {
  content: "";
  display: inline-block;
  width: 20px;

  height: 20px;
  margin-right: 8px;
  vertical-align: text-top;
  background: rgb(0, 185, 52);
  border: 1px solid #ddd;
  border-radius: 3px;
}

.custom-checkbox input[type="checkbox"]:checked+label:before {
  background-color: #1fdc6b;
  border-color: #27c9cf;

}

.custom-checkbox input[type="checkbox"]:checked+label:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  margin: 2px 7px;
  margin-left: 7px;
  margin-top: 3px;

}

/* No seu arquivo CSS */
.aviso {
  color: rgb(0, 255, 55);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}


/* Estilo para o texto das opções de alimentos */
.food-selection label span {
  font-size: 16px;
  margin-right: 5px;
}

.text-color {
  color: #333;
  accent-color: #319b00;

}

.Spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #009933;
  /* Define a cor do spinner (verde mais escuro) */
  position: relative;
}

/* Estilo para o emoji de comida */
.ComidaEmoji {
  position: absolute;
  top: -15px;
  /* Posiciona acima do spinner */
  left: -15px;
  /* Posiciona à esquerda do spinner */
  width: 40px;
  /* Largura do emoji de comida (um pouco maior) */
  height: 40px;
  /* Altura do emoji de comida (um pouco maior) */
  background-color: #00dc25;
  font-size: 1.5rem;
  /* Tamanho do emoji de comida */
  border-radius: 50%;
  /* Cria um background circular */
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoadingText {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Estilo para o aviso */
.AvisoNaoSaia {
  font-size: 1.4rem;
  font-weight: bold;
  color: red;
  margin-top: 10px;
}


@media (max-width: 530px) {

  .chat-conteiner {
    font-family: 'Inter', sans-serif;
    width: 100%;
  }

  .chat-inputs {
    width: 95%;

  }

  .gradient-header {
    width: 95%;

  }

  .selection-food {
    width: 95%;
  }

  .chat-response {
    margin: auto;
    width: 100%;
  }

  .food-item {
    min-width: calc(30%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 19s infinite;
}