:root {
  --primary-color: #17A34A;
  --primary-dark: #17A34A;
  --primary-light: #C8E6C9;
  --secondary-color: #e8f6ea;
  --text-color: #212121;
  --background-color: #FFFFFF;
  --card-background: #ecfcf2;
  --border-radius: 12px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

.app-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.app-header {
  background-color: var(--primary-color);
  color: white;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.app-main {
  padding: 1rem;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover:not(:disabled) {
  background-color: var(--primary-dark);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: 1px #388E3C solid;
}

.btn-secondary:hover:not(:disabled) {
  background-color: var(--primary-light);
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn.loading {
  position: relative;
  color: transparent;
}

.btn.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.list-content {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.list-content h2 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
  text-align: center;
}

.category-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.category-card {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 2px solid var(--primary-light);
}

.category-card h3 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  text-transform: capitalize;

}

.category-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.category-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-card li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid var(--primary-light);
}

.category-card li:last-child {
  border-bottom: none;
}

.item-name {
  font-size: 0.9rem;
  color: #212121;
  text-transform: capitalize;

}

.item-action {
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.item-action:hover {
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 600px) {
  .action-bar {
    flex-direction: column;
    gap: 0.5rem;
  }

  .btn {
    width: 100%;
  }
}

